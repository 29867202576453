import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.placeholders.maxWidth};
  padding: 100px 165px;
  gap: 110px;

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wl}) {
    padding: 100px 100px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding: 100px 50px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 60px 20px;
    gap: 30px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    flex-direction: column;
  }
`

export const HalfWidthContainer = styled.div<{
  display?: string
  direction?: 'row' | 'column'
}>`
  width: 50%;
  gap: 24px;
  ${({ display, direction }) =>
    display === 'flex' &&
    `display: ${display}; flex-direction: ${direction}`};

  .gatsby-image-wrapper {
    width: 80%;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    width: 100%;

    & > .gatsby-image-wrapper {
      width: 100%;
    }
  }
`
