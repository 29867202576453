import { graphql } from 'gatsby'
import indexAscendingSort from 'helpers/indexAscendingSort'
import { IHomePageProps } from 'interfaces/PageInterfaces'
import React from 'react'
import { Helmet } from 'react-helmet'

import {
  BannerSection,
  FeaturesSection,
  PartnerSection,
  ForCustomerSection,
  ExperienceSection,
  EnchanceSection,
  FacilitatorSection,
  SuadaHelpSection,
  TrustPilotSection,
} from 'sections/HomePage'

const IndexPage = ({ data }: IHomePageProps) => {
  const {
    bannerText,
    missionText,
    featuresText,
    featuresArticles,
    forCustomer,
    partners,
    digitalExperienceText,
    digitalExperienceArticles,
    learningExperienceText,
    learningExperienceArticles,
    facilitatorExperienceText,
    facilitatorExperienceArticles,
    whoWeHelpedText,
    whoWeHelpedTestimonials,
    suadaHelpArticles,
  } = data

  // sort with index key ascending
  const featuresArticlesSorted = featuresArticles.nodes
    .sort(indexAscendingSort)
    .slice(0, 3)

  const learningExperienceArticlesSorted =
    learningExperienceArticles.nodes
      .sort(indexAscendingSort)
      .slice(0, 3)

  return (
    <>
      <Helmet title="Learn with SUADA" defer={false} />
      <BannerSection {...bannerText} />
      <PartnerSection
        {...missionText}
        partners={partners.nodes}
      />
      <FeaturesSection
        {...featuresText}
        subHeaderLong={
          featuresText.subHeaderLong.subHeaderLong
        }
        articles={featuresArticlesSorted}
      />
      <ForCustomerSection forCustomer={forCustomer.nodes} />
      <TrustPilotSection
        {...whoWeHelpedText}
        reviews={whoWeHelpedTestimonials.nodes}
      />
      <ExperienceSection
        {...digitalExperienceText}
        articles={digitalExperienceArticles.nodes}
      />
      <EnchanceSection
        {...learningExperienceText}
        articles={learningExperienceArticlesSorted}
      />
      <FacilitatorSection
        {...facilitatorExperienceText}
        articles={facilitatorExperienceArticles.nodes}
      />
      <SuadaHelpSection
        articles={suadaHelpArticles.nodes}
      />
    </>
  )
}

export default IndexPage

export const pageData = graphql`
  query {
    bannerText: contentfulHomePageBannerTitleNew {
      block1BannerTitleMajor
      block1BannerTitleMinor
      block1BannerSubTitle
      block1BannerNote
    }
    missionText: contentfulHomePageMission {
      description
      mission
    }
    partners: allContentfulPartnerCompanies {
      nodes {
        logo {
          gatsbyImageData(width: 100, quality: 80)
        }
      }
    }
    featuresText: contentfulHomePageFeaturesTitle {
      header
      subHeader
      subHeaderLong {
        subHeaderLong
      }
    }
    featuresArticles: allContentfulHomePageFeaturesArticle {
      nodes {
        title
        description
        index
      }
    }
    forCustomer: allContentfulHomePageForCustomer {
      nodes {
        title
        description
        link
      }
    }
    digitalExperienceText: contentfulHomePageDigitalExperienceTitle {
      header
      subHeader
    }
    digitalExperienceArticles: allContentfulHomePageDigitalExperienceArticle {
      nodes {
        title
        description {
          description
        }
      }
    }
    learningExperienceText: contentfulHomePageLearningExperienceTitle {
      header
      subHeader
    }
    learningExperienceArticles: allContentfulHomePageLearningExperienceArticle {
      nodes {
        title
        description {
          description
        }
        index
      }
    }

    suadaHelpArticles: allContentfulHomePageSuadaHelpArticle {
      nodes {
        title
        description
      }
    }
    facilitatorExperienceText: contentfulHomePageFacilitatorExperienceTitle {
      header
      subHeader
    }
    facilitatorExperienceArticles: allContentfulHomePageFacilitatorExperienceArticle {
      nodes {
        title
        description {
          description
        }
      }
    }
    whoWeHelpedText: contentfulHomePageWhoWeHelpedTitle {
      header
      subHeader
    }
    whoWeHelpedTestimonials: allContentfulHomePageWhoWeHelpedTestemonial {
      nodes {
        fullName
        designation
        review {
          review
        }
        profilePicture {
          gatsbyImage(width: 100, quality: 90)
        }
        # embeddedVideo {
        #   childMarkdownRemark {
        #     html
        #   }
        # }
      }
    }
  }
`
