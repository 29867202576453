import React from 'react'
import { StyledText, CompanyLogo } from 'components/common'
import Partners from './ImageObjects'
import { SectionWrapper, PartnerLogos } from './styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { PhotoSlider } from 'components/carousels'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import backgroundColors from 'helpers/logoBackgroundColors'

const PartnerSection = ({
  mission,
  description,
  partners,
}: {
  mission: string
  description: string
  partners: {
    logo: IGatsbyImageData
  }[]
}) => {
  const images = useStaticQuery(imageQuery)

  return (
    <>
      <SectionWrapper>
        <StyledText fontSize="40px" textAlign="center" className="heading">
          {mission}
        </StyledText>
        <StyledText
          variant="light"
          textAlign={'center'}
          className={'partner-text'}
        >
          <span>{description}</span>
        </StyledText>

        <PartnerLogos>
          {partners.map(({ logo }, index) => {
            return (
              <CompanyLogo
                key={index}
                image={logo}
                alt={'logo'}
                backgroundColor={
                  backgroundColors[index % backgroundColors.length]
                }
              />
            )
          })}
        </PartnerLogos>
      </SectionWrapper>
      <PhotoSlider photos={Partners} gatsbyData={images} />
    </>
  )
}

export default PartnerSection

const imageQuery = graphql`
  query getAllImages {
    allFile(
      filter: {
        relativeDirectory: { eq: "images/logos" }
        # extension: { eq: "png" }
      }
    ) {
      nodes {
        relativePath
        relativeDirectory
        base
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
  }
`
