import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 34px;
  ${({ theme }) => theme.placeholders.maxWidth};
  padding: 70px 165px;

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wl}) {
    padding: 70px 100px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding: 70px 50px;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 70px 10px;
  }
`
export const AlignItemsCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -30px;
`
