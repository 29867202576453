import styled from 'styled-components'

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.placeholders.maxWidth};
  padding: 90px 165px;

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 70px 100px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 50px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    padding: 50px 20px;
    .heading {
      font-size: ${({ theme }) => theme.fontSizes.subHeading};
    }
  }

  .partner-text {
    font-size: ${({ theme }) => theme.fontSizes.subHeading};
    width: 90%;
    line-height: 35px;
    .ws {
      display: none;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
      font-size: ${({ theme }) => theme.fontSizes.body};
      width: 100%;
      .wm {
        display: none;
      }
      .ws {
        display: block;
      }
    }
  }
`

export const PartnerLogos = styled.div`
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    display: none;
  }
`
