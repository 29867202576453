import React from 'react'
import {
  FlexArticle,
  StyledButton,
  StyledText,
} from 'components/common'
import { FlexArticleContainer } from '../Features/styled-componens'
import { Wrapper } from './styled-components'
import { currentIconEnums } from 'interfaces/FlexArticleInterfaces'

const EnchanceSection = ({
  header,
  subHeader,
  articles,
}: {
  header: string
  subHeader?: string
  articles: {
    title: string
    description: {
      description: string
    }
  }[]
}) => {
  const articleIconEnums = [
    currentIconEnums.wavesReverse,
    currentIconEnums.ThreeXThree,
    currentIconEnums.pill,
    currentIconEnums.waves,
  ]
  const isSubheader =
    subHeader !== undefined && subHeader !== '!'
  return (
    <Wrapper>
      <StyledText
        textAlign="center"
        fontSize="32px"
        className="heading"
      >
        {header}
      </StyledText>
      {isSubheader && (
        <StyledText
          textAlign="center"
          variant="light"
          className="subheader max-width"
        >
          {subHeader}
        </StyledText>
      )}
      <FlexArticleContainer>
        {articles.map(({ title, description }, index) => {
          return (
            <FlexArticle
              key={index}
              icon={
                articleIconEnums[
                  index % articleIconEnums.length
                ]
              }
              title={title}
              description={description.description}
              gap={[16, 8]}
            />
          )
        })}
      </FlexArticleContainer>
      <StyledButton
        to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
        paddings={74}
      >
        See it in action
      </StyledButton>
    </Wrapper>
  )
}

export default EnchanceSection
