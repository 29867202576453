import styled from 'styled-components'

export const FlexWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${({ theme }) => theme.placeholders.maxWidth};
  z-index: 2;
  padding: 100px 165px;

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 100px 90px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 100px 50px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    .heading {
      font-size: ${({ theme }) => theme.fontSizes.subHeading};
    }

    .subheader {
      font-size: ${({ theme }) => theme.fontSizes.body};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 70px 20px;

    .link,
    .link .custom-button {
      width: 100%;
      justify-content: center;
    }
  }
`

export const FlexArticleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 65px 0;
  gap: 120px;

  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    flex-direction: column;
    margin: 40px 0;
    gap: 45px;
    .flex-wrapper {
      width: 100%;

      align-items: center;

      p {
        text-align: center;
        width: 100%;
        white-space: unset;
        @media (min-width: ${({ theme }) => theme.breakpoints.wxs}) {
          max-width: 500px;
        }
      }
    }
  }
`
