import React from 'react'
import {
  HalfWidthContainer,
  Wrapper,
} from './styled-components'

import TeacherSvg from 'images/svg/teacherinmonitor.svg'
import { ForCustomer } from 'components/common'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const ForCustomerSection = ({
  forCustomer,
}: {
  forCustomer: {
    title: string
    description: string
    link: string
  }[]
}) => {
  const sectionImageData = useStaticQuery(
    FOR_CUSTOMER_QUERY,
  ).file.childImageSharp.fluid
  return (
    <Wrapper>
      <HalfWidthContainer>
        <Img
          fluid={sectionImageData}
          className={'article-image'}
        />
      </HalfWidthContainer>
      <HalfWidthContainer display="flex" direction="column">
        {forCustomer.map((article, index) => (
          <ForCustomer key={index} {...article} />
        ))}
      </HalfWidthContainer>
    </Wrapper>
  )
}

export default ForCustomerSection

const FOR_CUSTOMER_QUERY = graphql`
  query forCustomerArticleImage {
    file(
      relativePath: { eq: "images/svg/computerNewIll.png" }
    ) {
      relativePath
      childImageSharp {
        fluid(quality: 85) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
