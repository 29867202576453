import { UsersReviewCarousel } from 'components/carousels'
import {
  ModalTestimonial,
  StyledText,
} from 'components/common'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { GlobalStyle } from 'layouts'
import React, { useState } from 'react'
import {
  AlignItemsCenter,
  Wrapper,
} from './styled-components'
import { TrustBox } from 'components/common/TrustPilot'

const Widget = ({
  header,
  subHeader,
  reviews,
}: {
  header: string
  subHeader: string
  reviews: {
    profilePicture: {
      gatsbyImage: IGatsbyImageData
    }
    fullName: string
    designation: string
    review: {
      review: string
    }
  }[]
}) => {
  const [activeReview, setActiveReview] = useState(-1)
  const isSubheader =
    subHeader !== undefined && subHeader !== '!'

  return (
    <>
      <GlobalStyle isNavOpen={activeReview !== -1} />
      <ModalTestimonial
        {...reviews[activeReview]}
        onClose={() => setActiveReview(-1)}
      />
      <Wrapper>
        <AlignItemsCenter>
          <StyledText
            textAlign="center"
            fontSize="32px"
            className="heading"
          >
            {header}
          </StyledText>
          {isSubheader && (
            <StyledText
              textAlign="center"
              variant="light"
              className="subheader max-width"
            >
              {subHeader}
            </StyledText>
          )}
          {/* <TrustBox /> */}
        </AlignItemsCenter>
        <UsersReviewCarousel
          reviews={reviews}
          setActiveReview={setActiveReview}
          activeReview={activeReview}
        />
      </Wrapper>
    </>
  )
}

export default Widget
