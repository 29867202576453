const backgroundColors = [
  '#f2fbfe',
  '#fef4f3',
  '#f2fbfa',
  '#f7fbfc',
  '#fbf5f9',
  '#f9fdfe',
  '#f2fafa',
  '#f8f5f9',
]

export default backgroundColors
