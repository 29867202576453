import React from 'react'
import {
  FlexArticle,
  StyledButton,
  StyledText,
  WaveWrapper,
} from 'components/common'
import { FlexContainer, Wrapper } from './styled-components'

import WomanWithLaptop from 'images/svg/womanWithLaptop.svg'
import NotesSvg from 'images/svg/notes.svg'
import BooksSvg from 'images/svg/books.svg'
import ManWithLaptop from 'images/svg/manWithLaptop.svg'
import { currentIconEnums } from 'interfaces/FlexArticleInterfaces'
import { graphql, useStaticQuery } from 'gatsby'
import {
  GatsbyImage,
  IGatsbyImageData,
} from 'gatsby-plugin-image'

const ArticleSvg = ({ index }: { index: number }) => {
  switch (index) {
    case 0:
      return <WomanWithLaptop className="articleImage" />
    case 1:
      return <NotesSvg className="articleImage" />
    case 2:
      return <BooksSvg className="articleImage" />
    case 3:
      return <ManWithLaptop className="articleImage" />
    default:
      return <NotesSvg className="articleImage" />
  }
}

const ExperienceSection = ({
  header,
  subHeader,
  articles,
}: {
  header: string
  subHeader?: string
  articles: {
    title: string
    description: {
      description: string
    }
  }[]
}) => {
  const articleIconEnums = [
    currentIconEnums.waves,
    currentIconEnums.TwoXTwo,
    currentIconEnums.ThreeXThreePurple,
    currentIconEnums.waves,
  ]
  const isSubheader =
    subHeader !== undefined && subHeader !== '!'

  const articleImagesFluidData = useStaticQuery(imageQuery)
    .allFile.nodes.sort((a: any, b: any) => {
      const textA = a.base.toUpperCase()
      const textB = b.base.toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    })
    .map(
      (node: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }) => node.childImageSharp.gatsbyImageData,
    )

  return (
    <WaveWrapper color={'pink'}>
      <Wrapper>
        <div>
          <StyledText
            textAlign="center"
            fontSize="32px"
            className="heading"
          >
            {header}
          </StyledText>
          {isSubheader && (
            <StyledText
              textAlign="center"
              variant="light"
              className="subheader max-width"
            >
              {subHeader}
            </StyledText>
          )}
        </div>
        <FlexContainer>
          {articles.map(({ title, description }, index) => {
            const className = `article-${index} flexWrapper`
            return (
              <div className={className} key={index}>
                <GatsbyImage
                  image={
                    articleImagesFluidData[
                      index % articleImagesFluidData.length
                    ]
                  }
                  alt="article"
                />
                <FlexArticle
                  themeColor="#7C2AC3"
                  icon={
                    articleIconEnums[
                      index % articleIconEnums.length
                    ]
                  }
                  title={title}
                  description={description.description}
                  gap={[32, 16]}
                  width="40%"
                />
              </div>
            )
          })}
          <StyledButton
            color="#8636CC"
            to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
            paddings={73}
          >
            See it in action
          </StyledButton>
        </FlexContainer>
      </Wrapper>
    </WaveWrapper>
  )
}

export default ExperienceSection

const imageQuery = graphql`
  query getExperienceImages {
    allFile(
      filter: {
        relativeDirectory: {
          eq: "images/svg/homepage-experience"
        }
        # extension: { eq: "png" }
      }
    ) {
      nodes {
        relativePath
        relativeDirectory
        base
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 90)
        }
      }
    }
  }
`
