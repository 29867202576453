const Partners = [
  {
    src: 'images/logos/barclays.png',
    alt: 'barclays',
    backgroundColor: '#f2fbfe',
  },
  {
    src: 'images/logos/bsi.png',
    alt: 'bsi',
    backgroundColor: '#fef4f3',
  },
  {
    src: 'images/logos/gbs.png',
    alt: 'gbs',
    backgroundColor: '#f2fbfa',
  },
  {
    src: 'images/logos/connells.png',
    alt: 'connells',
    backgroundColor: '#f7fbfc',
  },
  {
    src: 'images/logos/cambria.png',
    alt: 'cambria',
    backgroundColor: '#fbf5f9',
  },
  {
    src: 'images/logos/dnv.png',
    alt: 'dnv',
    backgroundColor: '#f9fdfe',
  },
  {
    src: 'images/logos/uop.png',
    alt: 'uop',
    backgroundColor: '#f2fafa',
  },
  {
    src: 'images/logos/ymca.png',
    alt: 'ymca',
    backgroundColor: '#f8f5f9',
  },
]

export default Partners
