import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.placeholders.maxWidth};
  padding: 50px 165px 100px 165px;

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 50px 100px 100px 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 50px 50px 100px 50px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 50px 20px 100px 20px;

    .link,
    .custom-button {
      width: 100%;
    }
  }
`

export const FlexArticleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 80px 0 64px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    flex-direction: column;
    width: 100%;
    gap: 60px;

    .flex-wrapper {
      width: 100%;
    }
  }
`
