import React from 'react'
import {
  FlexArticle,
  StyledButton,
  StyledText,
} from 'components/common'
import {
  Content,
  FlexArticleContainer,
  Wrapper,
} from './styled-compontents'

import Img from 'gatsby-image'
import { currentIconEnums } from 'interfaces/FlexArticleInterfaces'
import { graphql, useStaticQuery } from 'gatsby'

const FacilitatorSection = ({
  header,
  subHeader,
  articles,
}: {
  header: string
  subHeader?: string
  articles: {
    title: string
    description: {
      description: string
    }
  }[]
}) => {
  const articleIconEnums = [
    currentIconEnums.waves,
    currentIconEnums.ThreeXThreePurple,
    currentIconEnums.wavesReversePurple,
    currentIconEnums.TwoXTwo,
  ]
  const isSubheader =
    subHeader !== undefined && subHeader !== '!'

  const bannerImageData =
    useStaticQuery(BANNER_QUERY).file.childImageSharp.fluid
  return (
    <Wrapper>
      <StyledText textAlign="center" className="heading">
        {header}
      </StyledText>
      {isSubheader && (
        <StyledText
          textAlign="center"
          variant="light"
          className="subheader max-width"
        >
          {subHeader}
        </StyledText>
      )}
      <Content>
        <FlexArticleContainer>
          {articles.map(({ title, description }, index) => {
            return (
              <FlexArticle
                key={index}
                icon={
                  articleIconEnums[
                    index % articleIconEnums.length
                  ]
                }
                title={title}
                description={description.description}
                gap={[16, 8]}
                width={'100%'}
                themeColor="#7C2AC3"
              />
            )
          })}
        </FlexArticleContainer>
        <Img fluid={bannerImageData} />
      </Content>
      <StyledButton
        color="#8636CC"
        to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
        paddings={73}
      >
        See it in action
      </StyledButton>
    </Wrapper>
  )
}

export default FacilitatorSection

const BANNER_QUERY = graphql`
  query facilitatorImage {
    file(
      relativePath: {
        eq: "images/svg/Everything-final-fixed.png"
      }
    ) {
      relativePath
      childImageSharp {
        fluid(quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
