import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  ${({ theme }) => theme.placeholders.maxWidth};
  padding: 70px 165px;

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wl}) {
    padding: 70px 100px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding: 70px 50px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 40px 20px;
    gap: 0;

    .title {
      margin-bottom: -10px;
    }

    .link,
    .custom-button {
      width: 100%;
    }
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    flex-direction: column-reverse;
    svg {
      width: 80%;
    }

    & > :last-child {
      margin-block: 40px;
    }
  }
`

export const FlexArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 50%;

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    width: 100%;

    .flex-wrapper {
      align-items: center;

      p {
        text-align: center;
      }
    }
  }
`
