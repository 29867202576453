import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  StyledButton,
  StyledText,
  WaveWrapper,
} from 'components/common'
import {
  FlexArticleContainer,
  FlexWrapper,
} from './styled-componens'
import { FlexArticle } from 'components/common/FlexArticle'

import { currentIconEnums } from 'interfaces/FlexArticleInterfaces'

const FeaturesSection = ({
  header,
  subHeader,
  subHeaderLong,
  articles,
}: {
  header: string
  subHeader: string
  subHeaderLong: string
  articles: {
    title: string
    description: string
  }[]
}) => {
  const sparkleMap = [1, 2, 3, 4, 5]
  const articleIconEnums = [
    currentIconEnums.flag,
    currentIconEnums.share,
    currentIconEnums.videoCam,
    currentIconEnums.wavesReverse,
  ]
  return (
    <WaveWrapper color="lightGrey">
      {sparkleMap.map((sparkle, index) => {
        const className = `banner-sparkle sparkle-${sparkle}`
        return (
          <StaticImage
            key={index}
            src="../../../images/bannerSparkle.png"
            alt="banner background"
            placeholder="none"
            className={className}
          ></StaticImage>
        )
      })}
      <FlexWrapper>
        <StyledText textAlign="center" className="heading">
          {header}
        </StyledText>
        <StyledText
          variant="light"
          textAlign="center"
          className="subheader max-width"
        >
          {subHeaderLong}
        </StyledText>
        <FlexArticleContainer>
          {articles.map((article, index) => {
            return (
              <FlexArticle
                width="20%"
                key={index}
                icon={
                  articleIconEnums[
                    index % articleIconEnums.length
                  ]
                }
                {...article}
                gap={[16, 8]}
              />
            )
          })}
        </FlexArticleContainer>
        <StyledButton
          to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
          paddings={74}
        >
          See it in action
        </StyledButton>
      </FlexWrapper>
    </WaveWrapper>
  )
}

export default FeaturesSection
