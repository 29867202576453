import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 60px;
  ${({ theme }) => theme.placeholders.maxWidth};
  padding: 95px 165px;

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wl}) {
    padding: 95px 100px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding: 95px 50px;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 95px 20px;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    gap: 10px;
  }
`
export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 100px;
  width: 100%;

  .flexWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 450px;

    .gatsby-image-wrapper {
      width: 40%;
    }

    &:nth-child(2n) {
      flex-direction: row-reverse;
    }

    @media (max-width: ${({ theme }) =>
        theme.breakpoints.wxs}) {
      flex-direction: column;
      height: auto;

      .gatsby-image-wrapper {
        width: 80%;
        margin-block: 10px;
      }

      .flex-wrapper {
        width: 100%;
        align-items: center;
        p {
          text-align: center;
        }
      }

      &:nth-child(2n) {
        flex-direction: column !important;
      }
    }
  }

  .article-2 {
    margin-block: -120px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    .article-2 {
      margin-block: 0;
    }

    .article-1,
    .article-2 {
      .articleImage {
        display: none;
      }
    }

    .link,
    .link .custom-button {
      width: 100%;
      justify-content: center;
    }
  }

  .articleImage {
    width: 36%;
    height: 100%;
    @media (max-width: ${({ theme }) =>
        theme.breakpoints.wxs}) {
      width: 80%;
      margin-block: 50px;
    }
  }

  @media (max-width: 1750px) {
    gap: 70px;
  }
`
