import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
  ${({ theme }) => theme.placeholders.maxWidth};
  padding: 100px 165px 100px 165px;

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 100px 100px 100px 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 100px 50px 70px 50px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 100px 20px 50px 20px;
    gap: 0;

    .link,
    .custom-button {
      width: 100%;
    }
  }
`
