import {
  StyledButton,
  StyledText,
  WaveWrapper,
} from 'components/common'
import Img from 'gatsby-image'
import React from 'react'
import {
  TextContainer,
  FlexWrapper,
} from './styled-components'

import BannerSparkle from 'images/svg/bannerSparkle.svg'
import { Helmet } from 'react-helmet'
import { TrustBox } from 'components/common/TrustPilot'
import { graphql, useStaticQuery } from 'gatsby'

const BannerSection = ({
  block1BannerTitleMajor,
  block1BannerTitleMinor,
  block1BannerSubTitle,
  block1BannerNote,
}: {
  block1BannerTitleMajor: string
  block1BannerTitleMinor: string
  block1BannerSubTitle: string
  block1BannerNote: string
}) => {
  const bannerImageData =
    useStaticQuery(BANNER_QUERY).file.childImageSharp.fluid
  const sparkleMap = [1, 2, 3, 4, 5]

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
      </Helmet>
      <WaveWrapper color="lightGreen">
        {sparkleMap.map((sparkle, index) => {
          const className = `banner-sparkle sparkle-${sparkle}`
          return (
            <BannerSparkle
              className={className}
              key={index}
            />
          )
        })}
        <FlexWrapper>
          <TextContainer>
            <StyledText
              textAlign="left"
              fontSize="54px"
              className="heading"
              lineHeight="1.2"
            >
              {block1BannerTitleMinor}
            </StyledText>
            <StyledText
              textAlign="left"
              lineHeight="1.2"
              fontSize="54px"
              className="heading major"
              textColor="#8636CC !important"
            >
              {block1BannerTitleMajor}
            </StyledText>
            <StyledText
              textAlign="left"
              fontSize="18px"
              fontType="regular"
              className="sub-heading"
              fontWeight={400}
            >
              {block1BannerSubTitle}
            </StyledText>
            <StyledText
              textAlign="left"
              fontSize="18px"
              fontType="regular"
              className="sub-heading"
              fontWeight={400}
            >
              {block1BannerNote}
            </StyledText>
            {/* <StyledButton>See it in action</StyledButton> */}
            {/* <TrustBox className={'responsive-top'} /> */}
          </TextContainer>
          <Img fluid={bannerImageData} />
          {/* <TrustBox className={'responsive'} /> */}
          <StyledButton className="responsive">
            See it in action
          </StyledButton>
        </FlexWrapper>
      </WaveWrapper>
    </>
  )
}

export default BannerSection

const BANNER_QUERY = graphql`
  query homePageBanner {
    file(
      relativePath: {
        eq: "images/svg/Everything-final-fixed.png"
      }
    ) {
      relativePath
      childImageSharp {
        fluid(quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
